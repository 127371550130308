import clsx from "clsx"
import React from "react"
import {KTIcon} from "../../../helpers"
import {Link} from "react-router-dom"

const MessagesSwitch = () => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-200px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3 my-0'>
        <Link to='/pending-messages' className={clsx("menu-link px-3 py-2")}>
          <span className='menu-icon' data-kt-element='icon'>
            <KTIcon iconName='loading' className='fs-1' />
          </span>
          <span className='menu-title'>Messages en attente</span>
        </Link>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-3 my-0'>
        <Link to='/messages' className={clsx("menu-link px-3 py-2")}>
          <span className='menu-icon' data-kt-element='icon'>
            <KTIcon iconName='messages' className='fs-1' />
          </span>
          <span className='menu-title'>Discussions</span>
        </Link>
      </div>
    </div>
  )
}

export default MessagesSwitch
