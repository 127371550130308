import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react"
import {LayoutSplashScreen} from "../../../../_metronic/layout/core"
import {AuthModel, UserModel} from "./_models"
import * as authHelper from "./AuthHelpers"
import {WithChildren} from "../../../../_metronic/helpers"
import {getUserInfo, logoutApi} from "./_requests"

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: any | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: undefined,
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuthManually] = useState<AuthModel | undefined>(authHelper.getAuth())

  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    setAuthManually(undefined)
    logoutApi()
      .then(() => {
        saveAuth(undefined)
        setCurrentUser(undefined)
      })
      .catch((err) => console.log(err))
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, saveAuth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUpdatedUser = async () => {
      try {
        if (auth) {
          const response = await getUserInfo()

          if (response.status === 200) {
            const currentAuth = {
              user: response.data.data,
              token: auth.token,
            }
            saveAuth(currentAuth)
            setCurrentUser(currentAuth.user)
          } else {
            console.error("getting user info failed")
          }
        }

        //  setCurrentUser(auth?.user)
      } catch (error) {
        console.error(error)
        logout()
        setShowSplashScreen(false)
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth) {
      requestUpdatedUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
