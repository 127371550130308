const Loading = () => {
  return (
    <div className='mx-auto'>
      <span>
        <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
      </span>
    </div>
  )
}

export {Loading}
