import {createRoot} from "react-dom/client"
// Axios
import {Chart, registerables} from "chart.js"
import {QueryClient, QueryClientProvider} from "react-query"
import {ReactQueryDevtools} from "react-query/devtools"
// Apps
import {MetronicI18nProvider} from "./_metronic/i18n/Metronici18n"
import "./_metronic/assets/fonticon/fonticon.css"
import "./_metronic/assets/keenicons/duotone/style.css"
import "./_metronic/assets/keenicons/outline/style.css"
import "./_metronic/assets/keenicons/solid/style.css"
import "./custom.css"
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss"
import "./_metronic/assets/sass/plugins.scss"
import "./_metronic/assets/sass/style.react.scss"
import {AppRoutes} from "./app/routes/AppRoutes"
import {AuthProvider} from "./app/modules/auth"
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

// custom assests
import "./app/assets/css/custom.css"

Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById("root")
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
