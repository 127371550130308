import {useActivitiesContext} from "./core/ActivitiesContext"

type Props = {
  meta: {
    current_page: number
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
  }
}

const ActivitiesPagination = ({meta}: Props) => {
  const {page, setPage} = useActivitiesContext()
  const handlePreviousClick = () => {
    if (page > 1) {
      setPage((prev) => prev - 1)
    }
  }
  const handleNextClick = () => {
    if (page < meta.last_page) {
      setPage((prev) => prev + 1)
    }
  }
  return (
    <ul
      className='pagination'
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "end",
      }}
    >
      <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
        <span className='page-link cursor-pointer' onClick={handlePreviousClick}>
          <i className='previous'></i>
        </span>
      </li>
      {/* First <li> */}
      <li
        className={`page-item  ${meta.current_page === page && "active"} `}
        onClick={() => {
          if (page !== 1) {
            setPage((prev) => prev - 1)
          }
        }}
      >
        <span className='page-link cursor-pointer'>{meta.current_page}</span>
      </li>
      {/* Second <li> */}
      <li
        className={`page-item ${meta.current_page + 1 === page && "active"} ${
          meta.current_page + 1 > meta.last_page ? "disabled" : ""
        }`}
      >
        <span className='page-link cursor-pointer' onClick={() => setPage(meta.current_page + 1)}>
          {meta.current_page + 1}
        </span>
      </li>
      {/* Third <li> */}
      <li
        className={`page-item ${meta.current_page + 2 === page && "active"} ${
          meta.current_page + 2 > meta.last_page ? "disabled" : ""
        }`}
      >
        <span className='page-link cursor-pointer' onClick={() => setPage(meta.current_page + 2)}>
          {meta.current_page + 2}
        </span>
      </li>
      <li className={`page-item ${page === meta.last_page ? "disabled" : ""}`}>
        <span className='page-link cursor-pointer' onClick={handleNextClick}>
          <i className='next'></i>
        </span>
      </li>
    </ul>
  )
}

export default ActivitiesPagination
