import {AuthModel} from "./_models"
import axios from "axios"

const AUTH_LOCAL_STORAGE_KEY = "userAuth"

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error)
  }
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  responseType: "json",
})

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const userAuth = window.localStorage.getItem("userAuth")
      if (userAuth) {
        const parsedAuth = JSON.parse(userAuth)
        config.headers.Authorization = `Bearer ${parsedAuth.token}`
      }
    } catch (error) {
      // Handle AsyncStorage error, such as token not found
      console.error("Error retrieving token:", error)
    }
    return config
  },
  (error) => Promise.reject(error)
)

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, axiosInstance}
