// import {MenuItem} from "./MenuItem"

export function MenuInner() {
  return (
    <>
      {/* <MenuItem title='Gestion des rendez-vous' icon='abstract-18' to='/appointments' />
      <MenuItem
        icon='abstract-28'
        to='/apps/user-management/users'
        title='Gestion des utilisateurs'
      /> */}
      {/* <MenuItem title='Configuration' to='/builder' icon='switch' /> */}
    </>
  )
}
