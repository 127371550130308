// export const VERIFY_NUMBER = `${API_URL}/verify`
// export const LOGIN_URL = `${API_URL}/login`
// export const REGISTER_URL = `${API_URL}/register`
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot`

import {axiosInstance} from "./AuthHelpers"

// Server should return AuthModel
export function login(email: string, password: string) {
  return axiosInstance.post("/login", {
    email,
    password,
  })
}

export const register = (data: FormData) => {
  return axiosInstance.post(`/register`, data, {})
}

export const verifyOtpApi = async (otp) => {
  return await axiosInstance.post(`/verify`, {code: otp})
}

export function verifyExistence(email: string, token: string) {
  return axiosInstance.post("/verifyExistence", {email: email, token: token})
}

export function reset(
  email: string,
  token: string,
  password: string,
  password_confirmation: string
) {
  return axiosInstance.post("/reset", {
    email: email,
    token: token,
    password: password,
    password_confirmation: password_confirmation,
  })
}

export const logoutApi = async () => {
  return await axiosInstance.post(`/logout`)
}

export const getUserInfo = () => {
  return axiosInstance.get(`/my-account`)
}
