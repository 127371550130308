import axiosInstance from "../axios"

const getAllPendingMessagesApi = async () => {
  return axiosInstance.get("/admin/messages")
}

const getAllConversationsApi = async () => {
  return axiosInstance.get("/admin/conversations")
}

const createConversationApi = async (data) => {
  return axiosInstance.post("/admin/conversations/create", data)
}

const getConversationApi = async (id, page) => {
  return axiosInstance.get(`/admin/conversation?messenger=${id}&page=${page}`)
}

const sendMessageApi = async (data) => {
  return axiosInstance.post("/admin/messages/send", data)
}

export {
  getAllPendingMessagesApi,
  sendMessageApi,
  getAllConversationsApi,
  getConversationApi,
  createConversationApi,
}
