import {Dispatch, SetStateAction, createContext, useContext, useState} from "react"
import {useQuery} from "react-query"
import {getActivities} from "./_requests"
import {AxiosResponse} from "axios"

type ActivitiesContextProps = {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  isFetching: boolean
  response: AxiosResponse<any> | any
  refetch: any
}

const ActivitiesContextInitial = {
  page: 1,
  isFetching: false,
  setPage: () => {},
  response: {},
  refetch: () => {},
}
const ActivitiesContext = createContext<ActivitiesContextProps>(ActivitiesContextInitial)
export const useActivitiesContext = () => {
  return useContext(ActivitiesContext)
}

export const ActivitiesContextProvider = ({children}) => {
  const [page, setPage] = useState<number>(ActivitiesContextInitial.page)

  const {
    isFetching,
    data: response,
    refetch,
  } = useQuery(
    [`Fetching Activities-page-${page}`],
    () => {
      return getActivities(page)
    },
    {}
  )

  return (
    <ActivitiesContext.Provider value={{page, setPage, isFetching, response, refetch}}>
      {children}
    </ActivitiesContext.Provider>
  )
}
