const QUERIES = {
  USERS_LIST: "users-list",
  DEMANDS_LIST: "demands-list",
  SINGLE_DEMAND: "single-demands",
  SINGLE_DEMAND_CANCEL: "single-demands-cancel",
  SINGLE_DEMAND_REJECT: "single-demands-reject",
  SINGLE_DEMAND_ACCEPT: "single-demands-accept",
  SINGLE_DEMAND_UPDATE: "single-demands-accept",
  SINGLE_DEMAND_LETTER: "single_demands_letter",
  DEMANDS_LETTER: "demands_letter",
  EXAMS_LIST: "exams-list",
  SERVICES_LIST: "services-list",
  SPECIALITIES: "specialities-list",
}

export {QUERIES}
