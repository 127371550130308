import axios from "axios"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  headers: {
    "Content-Type": "multipart/form-data",
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const userAuth = window.localStorage.getItem("userAuth")
      if (userAuth) {
        const parsedAuth = JSON.parse(userAuth)
        config.headers.Authorization = `Bearer ${parsedAuth.token}`
      }
    } catch (error) {
      // Handle AsyncStorage error, such as token not found
      console.error("Error retrieving token:", error)
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default axiosInstance
